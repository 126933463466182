export const URL_MOBILITY_ORDERHSITORY = 'https://mapac.thermofisher.cn/my-orders'
export const URL_GLOBAL_ORDERHSITORY = 'https://www.thermofisher.cn/store/orders/history'
export const URL_MOBILITY_PRODUCT = 'https://mapac.thermofisher.cn/product-details/'
export const URL_GLOBAL_PRODUCT = 'https://www.thermofisher.cn/order/catalog/product/'
// export const API_ORDER_DETAIL = 'https://track.thermofisher.cn/aem-api/logistics/tracking?orderNumber='
// export const API_SHIPMENT_DETAIL = 'https://track.thermofisher.cn/aem-api/logistics/shipment/tracking?shipmentNumbers='
//export const API_ORDER_DETAIL = 'https://aem-qa.service-hub.thermofisher.cn/aem-api/logistics/tracking?orderNumber='
// export const API_SHIPMENT_DETAIL = 'https://aem-qa.service-hub.thermofisher.cn/aem-api/logistics/shipment/tracking?shipmentNumbers='
export const API_ORDER_DETAIL = process.env.REACT_APP_API_ORDER_DETAIL
export const API_SHIPMENT_DETAIL = process.env.REACT_APP_API_SHIPMENT_DETAIL
console.log('process.env.variables', API_ORDER_DETAIL, API_SHIPMENT_DETAIL)
export const TOKEN = 'eyJhbGciOiJIUzM4NCJ9.eyJqdGkiOiJhZW1fbWlsZXN0b25lQHRoZXJtb2Zpc2hlci5jb20iLCJsb2dpbkVtYWlsIjoiYWVtX21pbGVzdG9uZUB0aGVybW9maXNoZXIuY29tIiwidXNlck5hbWUiOiJhZW1fbWlsZXN0b25lIiwic3ViIjoiYWVtX21pbGVzdG9uZUB0aGVybW9maXNoZXIuY29tIn0.RJGSqcChr1XYZHxOfMyxW6v_dqnxnRO4aZfoMoaFxY8Og18r6alay7-lMcbIQ6TU'
export const breakPoints = {
    sm: 576,
    md: 1024,
    lg: 992,
    xl: 1200,
}
export const getStatus = (status) => {
    const {eventCode, eventDescription} = status
    switch (eventCode) {
        case 'DTD':
            return { desc: '已签收', icon: '/images/tracking-success.svg' }
        case 'TRN':
            return {desc: '已发运', icon: '/images/tracking-ship.svg'}
        case 'OUT_FOR_DELIVERY':
            return '派送中'
        case 'FAILURE':
            return '投递失败'
        case 'PENDING':
            return '等待中'
        case 'EXCEPTION':
            return '异常'
        case 'EXPIRED':
            return '已过期'
        case 'MAN':
            return {desc: '已揽收', icon: '/images/tracking-progress.svg'}
        default:
            return {desc: status.eventDescription, icon: null}
    }
}
import React from "react";
import styles from "./OrderTracking.module.scss";
import { useIntl } from "react-intl";
import { getStatus } from "./trackingUtils";
import OrderTracking from "./OrderTracking";
import ShipmentDetail from "./ShipmentDetail";

function Shipment(props) {
  const { formatMessage } = useIntl();
  const f = (id, values) => formatMessage({ id }, values);
  const { orderDetail } = props;
  console.log("orderDetail", orderDetail);
  const [collapsed, setCollapsed] = React.useState(false);

  return (
    <div>
      {orderDetail?.map((packageItem) => {
        return (
          packageItem.orderSource === 'SAP订单' ? 
          <ShipmentDetail orderDetail={packageItem?.orderTracking} orderSource={packageItem?.orderSource}/>
          :
          <OrderTracking orderDetail={packageItem}/>
        );
        // return (
        //   <div className={styles.packListWrapper}>
        //     <div className={styles.packItemWrapper}>
        //       <div className={styles.shimentHeader}>
        //         <div className={`head3 ${styles.row1}`}>
        //           <label>{f("SHIPMENT_NUMBER")}:</label>
        //           <span>{packageItem.shipmentNumber}</span>
        //         </div>
        //         <div className={styles.row2}>
        //           {statusObj.icon && (
        //             <img alt={statusObj.desc} src={statusObj.icon}></img>
        //           )}
        //           <label>{packageItem.stateDescription}</label>
        //           <span>{packageItem.stateTime}</span>
        //         </div>
        //       </div>
        //       <div
        //         className={
        //           collapsed
        //             ? `${styles.packItemContentWrapper} collapsed`
        //             : `${styles.packItemContentWrapper}`
        //         }
        //       >
        //         <div className={styles.shipmentWrapper}>
        //           <div className={styles.carrierWrapper}>
        //             <div className={styles.row}>
        //               {packageItem.expressNumber ? (
        //                 <div className={styles.column1}>
        //                   <label className={styles.lable1}>
        //                     {f("TRACKER_NUMBER")}:
        //                   </label>
        //                   <span>{packageItem.expressNumber}</span>
        //                 </div>
        //               ) : null}
        //               <div className={styles.column2}>
        //                 <label>{f("CARRIER_NAME")}:</label>
        //                 <span>{packageItem.carrierName}</span>
        //               </div>
        //             </div>
        //           </div>
        //           <div className={styles.timelineHead}>
        //             <label>{f("ACTIVITY")}</label>
        //           </div>
        //           <ul className={styles.timelineWrapper}>
        //             {packageItem?.events?.map((timeline) => {
        //               return (
        //                 <li
        //                   key={timeline.eventTime}
        //                   className={styles.timelineItem}
        //                 >
        //                   <div className={styles.timelineSeparator}>
        //                     <span className={styles.timelineDot}></span>
        //                     <span className={styles.timelineConnector}></span>
        //                   </div>
        //                   <div className={styles.timelineContent}>
        //                     <div>
        //                       <span>{timeline.eventTime}</span>
        //                       <span
        //                         style={{
        //                           marginLeft: "0.5rem",
        //                         }}
        //                       >
        //                         {timeline.status}
        //                       </span>
        //                     </div>
        //                     <div>
        //                       <span>{timeline.eventDescription}</span>
        //                       <span
        //                         style={{ paddingLeft: "5px", fontSize: "12px" }}
        //                       >
        //                         {timeline.eventLocation || ''}
        //                       </span>
        //                     </div>
        //                   </div>
        //                 </li>
        //               );
        //             })}
        //           </ul>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // )
      })}
    </div>
  );
}

export default Shipment;

import "./App.scss";
import OrderInput from "./pages/orderinput/OrderInput";
import { IntlProvider } from "react-intl";
import locales from "./locale";
import { useEffect, useState } from "react";
import OrderTracking from "./pages/orderTracking/OrderTracking";
import Shipment from "./pages/orderTracking/Shipment";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { TermOfService } from "./components/footer";
import { useIntl } from "react-intl";
import { Alert, MenuItem, Select, OutlinedInput } from "@mui/material";
import {
  API_ORDER_DETAIL,
  API_SHIPMENT_DETAIL,
  TOKEN,
  URL_GLOBAL_ORDERHSITORY,
  URL_MOBILITY_ORDERHSITORY,
  breakPoints,
} from "./common/constant";
import { isMobile } from "./common/utils";

function IndexPage(props) {
  const { formatMessage } = useIntl();
  const f = (id, values) => formatMessage({ id }, values);
  const [orderDetail, setOrderDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [unlegalMsg, setUnlegalMsg] = useState("");
  const [detaiType, setDetailType] = useState(""); // orderDetail, shipment
  // const smallDevice = window.screen.availWidth <= 576
  const [inputType, setInputType] = useState("salesOrderNo");
  const [orderNumber, setOrderNumber] = useState("");
  const [smallDevice, setSmallDevice] = useState(
    window.innerWidth <= breakPoints.md
  );
  const [locale, setLocale] = useState("zh");
  const handleGetOrderDetail = (numberType, orderNum) => {
    if (!orderNum) return;
    setLoading(true);
    const reqUrl =
      numberType === "salesOrderNo" ? API_ORDER_DETAIL : API_SHIPMENT_DETAIL;
    if (numberType === "salesOrderNo") {
      setDetailType("orderDetail");
    } else {
      setDetailType("shipment");
    }
    axios
      .get(`${reqUrl}${orderNum}`, {
        headers: {
          countryCode: "cn",
          languageCode: "zh",
          Authorization: TOKEN,
        },
      })
      .then((res) => {
        console.log("res", res);
        setLoading(false);
        if (res?.data?.status === "success") {
          if (!res.data.data || res.data.data.length === 0) {
            setUnlegalMsg(f("COMMON_NO_DATA"));
            setOrderDetail(null);
            return;
          }
          setUnlegalMsg("");
        } else {
          if (res?.data?.status === "NO_ORDER_FOUND") {
            setUnlegalMsg(f("NOTEXIST"));
          } else {
            setUnlegalMsg(f("COMMON_ERROR_MSG"));
          }
        }
        setOrderDetail(res.data?.data);
        //routes.push('/orderDetail')
        //navigate('/shipment')
      })
      .catch((err) => {
        setUnlegalMsg(f("COMMON_ERROR_MSG"));
        setOrderDetail(null);
        console.log("err", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    const resizeHandler = () => {
      console.log("window.innerWidth", window.innerWidth);
      const isSmall =
        window.innerWidth <= breakPoints.md ||
        window.screen?.availWidth <= breakPoints.md;
      setSmallDevice(isSmall);
    };
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);
  const gotoOrderHistory = (e) => {
    if (e.target.id !== "gotoOrderHistory") return;
    if (isMobile()) {
      window.location.href = URL_MOBILITY_ORDERHSITORY;
    } else {
      window.open(URL_GLOBAL_ORDERHSITORY);
    }
  };

  return (
    <div className="App">
      <div className="App-headerWrapper">
        <header className="pageContentContainer App-header">
          <div className="App-head">
            <img
              src="/logo.svg"
              className="App-logo"
              alt="logo"
              onClick={() => {
                window.open(
                  "https://www.thermofisher.cn/cn/zh/home.html",
                  "_blank"
                );
              }}
            />
            <h1 className="App-Title">{f("APP_NAME")}</h1>
          </div>
          <div className="App-headright">
            {!smallDevice && (
              <OrderInput
              inputType={inputType}
              setInputType={setInputType}
              orderNumber={orderNumber}
              setOrderNumber={setOrderNumber}
              handleGetOrderDetail={handleGetOrderDetail} />
            )}
          </div>
          <Select
            className="App-locale"
            value={locale}
            onChange={(e) => {
              props.handleLocaleChange(e.target.value);
              setLocale(e.target.value);
            }}
            input={<OutlinedInput />}
          >
            <MenuItem value={"zh"}>中文</MenuItem>
            <MenuItem value={"en"}>English</MenuItem>
          </Select>
        </header>
      </div>
      {/* <RouterProvider router={router}>
      </RouterProvider> */}
      <div className={"pageContentContainer minPageContentContainer"}>
        <div className="mainContainer">
          {smallDevice && (
            <OrderInput
            inputType={inputType}
            setInputType={setInputType}
            orderNumber={orderNumber}
            setOrderNumber={setOrderNumber}
            handleGetOrderDetail={handleGetOrderDetail} />
          )}

          {loading ? (
            <div className="loadingMask">
              <div className="loadingIconWrapper">
                <CircularProgress></CircularProgress>
              </div>
            </div>
          ) : (
            <>
              {unlegalMsg && !orderDetail && (
                <Alert
                  severity="warning"
                  className="alertWrapper"
                >
                  {unlegalMsg}
                </Alert>
              )}
              {orderDetail && detaiType === "orderDetail" && (
                <OrderTracking orderDetail={orderDetail} />
              )}
              {orderDetail && detaiType === "shipment" && (
                <Shipment orderDetail={orderDetail} />
              )}
            </>
          )}
          {!orderDetail && (
            <div className="toptipWrapper">
              <div>
                <div
                  onClick={gotoOrderHistory}
                  dangerouslySetInnerHTML={{
                    __html: f("OrderHistoryLink"),
                  }}
                ></div>
                <p className="note">{f("OrderHistoryLinkNote")}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="navoutWrapper">
        <div
          className="navWrapper pageContentContainer"
          onClick={(e) => {
            const id = e.target.id || e.target.parentNode?.id;
            if (!id) return;
            if (id === "PromoCenter") {
              window.open(
                "https://www.thermofisher.cn/cn/zh/home/promo/campaigns.html",
                "_blank"
              );
              return;
            }
            if (id === "MemberCenter") {
              window.open("https://mapac.thermofisher.cn/membership", "_blank");
              return;
            }
            if (id === "HelpCenter") {
              window.open(
                "https://www.thermofisher.cn/cn/zh/home/support.html",
                "_blank"
              );
              return;
            }
            if (id === "Mobility") {
              window.open("https://mapac.thermofisher.cn/home", "_blank");
              return;
            }
          }}
        >
          <div className="navItem" id="PromoCenter">
            <div className="navInner">{f("PromoCenter")}</div>
          </div>
          <div className="navItem" id="MemberCenter">
            <div className="navInner">{f("MemberCenter")}</div>
          </div>
          <div className="navItem" id="HelpCenter">
            <div className="navInner">{f("HelpCenter")}</div>
          </div>
          <div className="navItem" id="Mobility">
            <div className="navInner">{f("Mobility")}</div>
          </div>
        </div>
      </div>
      <TermOfService />
    </div>
  );
}
function App() {
  const [locale, setLocale] = useState("zh");
  const handleLocaleChange = (val) => {
    setLocale(val);
  };
  return (
    <IntlProvider
      locale={locale}
      defaultLocale={locale}
      messages={locales[locale]}
    >
      <IndexPage handleLocaleChange={handleLocaleChange}></IndexPage>
    </IntlProvider>
  );
}

export default App;

export const en = {
    APP_NAME: 'Order Tracking',
    ORDER_NUMBER: 'Order number',
    E1_ORDER: 'E1 Order',
    SAP_ORDER: 'SAP Order',
    DN_EDT: 'Estimated delivery time',
    PendingShip: 'Pending shipment',
    PendingShipDefault: '待确认',
    EstimateShippingDate:'Estimated shipping date',
    SHIPMENTS: 'shipments',
    SHIPMENT_NUMBER: 'Shipment number',
    CARRIER_NAME: 'Carrier',
    CATALOGNUMBER: 'Catalog Number',
    QUANTITY: 'Qty',
    THISSHIPMENT: 'In this Shipment',
    ITEMS: 'Items',
    TRACKER_NUMBER: 'Tracking number',
    shipperCity_consigneeCity: 'Shipper City/Destination',
    offeringType: 'Offering Type',
    ACTIVITY: 'Activity detail',
    PRIVACY: 'Privacy Information Center',
    TERMS: 'Terms & Conditions',
    NOTEXIST: 'The number does not exist, Please check if the order number or logistics number is correct!',
    COMMON_ERROR_MSG: 'System error, please try again!',
    COMMON_NO_DATA: 'There is no data for this number, please check if the order number or logistics number is correct!',
    NOSHIPMENT: 'The logistics information has not been updated yet, please be patient and wait!',
    SalesOrderNumber: 'Sales Order Number',
    DeliveryNumber: 'Delivery Number',
    SalesOrderNumberHolder: 'Enter your sales order number',
    DeliveryNumberHolder: 'Enter your delivery number',
    ORDER_CreatedDate: 'Order placed',
    OrderHistoryLink: `
    Please click <span
    class='App-link'
    id='gotoOrderHistory'
    >Order history</span> to check more details.`,
    OrderHistoryLinkNote: 'Note: If this order is not placed by using your account, order details will not be provided.',
    PromoCenter: 'Promo Center',
    MemberCenter: 'Membership',
    HelpCenter: 'Help & Support',
    Mobility: 'Mobility',
}

import React, { useState } from "react";
import styles from "./OrderInput.module.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import { CancelRounded } from "@mui/icons-material";
import { useIntl } from "react-intl";

function OrderInput(props) {
  // const [inputType, setInputType] = useState("salesOrderNo");
  // const [orderNumber, setOrderNumber] = useState("");
  const { inputType, setInputType, orderNumber, setOrderNumber } = props;
  const { formatMessage } = useIntl();
  const f = (id, values) => formatMessage({ id }, values);
  return (
    <div className={styles.formWrapper}>
      <Select
        displayEmpty
        value={inputType}
        className={styles.formItemSelect}
        onChange={(e) => {
          setInputType(e.target.value);
        }}
        input={<OutlinedInput />}
        inputProps={{ "aria-label": "Select type" }}
      >
        <MenuItem
          value={"salesOrderNo"}
          // style={getStyles(name, personName, theme)}
        >
          {f("SalesOrderNumber")}
        </MenuItem>
        <MenuItem
          value={"deliveryNO"}
          // style={getStyles(name, personName, theme)}
        >
          {f("DeliveryNumber")}
        </MenuItem>
      </Select>
      <div className={styles.formItemInput}>
        <input
          type="text"
          autoComplete={"on"}
          autoFocus={true}
          id="orderNumber"
          placeholder={
            inputType === "salesOrderNo"
              ? f("SalesOrderNumberHolder")
              : f("DeliveryNumberHolder")
          }
          value={orderNumber}
          onChange={(e) => {
            setOrderNumber(e.target.value);
          }}
        />
        {orderNumber && (
          <CancelRounded
            className={styles.clearIcon}
            onClick={() => {
              setOrderNumber("");
            }}
          ></CancelRounded>
        )}
      </div>
      <button
        className={
          orderNumber
            ? styles.formItemBtn
            : `${styles.formItemBtn} ${styles.disabled}`
        }
        disabled={!orderNumber}
        onClick={() => {
          props.handleGetOrderDetail(inputType, orderNumber);
        }}
      >
        <SearchIcon></SearchIcon>
      </button>
    </div>
  );
}

export default OrderInput;

import React from 'react';
import { useIntl } from 'react-intl';

export function TermOfService() {

  const { formatMessage } = useIntl();
  const f = (id) => formatMessage({ id });

  const hrefGen = (href) => {

    return `https://www.thermofisher.cn/${'cn'}/${'zh'}/home/global/${href}.html`
  }

  return (
      <div
      className={'pageContentContainer'}
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        background: '#fff',
        justifyContent: 'space-between',
        paddingTop: '8px',
        paddingBottom: '25px',
        fontSize: '14px',

      }}>
        <div style={{paddingTop: '8px'}}>
          <div>
            <a className='App-link' target='blank' href={hrefGen('terms-and-conditions')}>{f('TERMS')}</a>
            <span style={{color: '#e5e5e5'}}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <a className='App-link' target='blank' href={hrefGen('privacy-policy')}>{f('PRIVACY')}</a>
          </div>
        </div>
        <div style={{paddingTop: '8px'}}>
          <img alt='' height={11} width={16} src={`/Flag_of_the_People's_Republic_of_China.svg`}></img>
          &nbsp;China | 中国
        </div>

      </div>
  );
}
